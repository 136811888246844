import { Box, InfoBox, Typography } from '@affinidi/component-library'
import { Loader } from '@aws-amplify/ui-react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { useState, useLayoutEffect, ReactNode } from 'react'
import CustomErrorView from './CustomErrorView/CustomErrorView'
import { getEnv } from '../env'
import { EVENTS, postMessageToParent } from '../features/windowMessaging'
import { isMobileScreen } from '../utils/device'

const env = getEnv()

export type LivenessData = {
  sessionId: string
  message: string
  providerId: string
  status: string
  ageRange: {
    low: number
    high: number
  }
}

const handleAnalysisComplete = async () => {
  console.log('Liveness check analysis is completed')
  postMessageToParent(EVENTS.ANALYSIS_COMPLETED)
}

const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box alignItems="center" justifyContent="center" style={{ height: '100%', width: '100%' }}>
      {children}
    </Box>
  )
}

const FaceLiveness = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sessionId, setSessionId] = useState<string | null>()
  const [cameraSelectDisabled, setCameraSelectDisabled] = useState(true)
  const isMobile = isMobileScreen()

  const handleCameraSelectPage = async () => {
    const SINGLE_CAMERA = 1
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    const cameras = mediaDevices.filter(({ kind }) => kind === 'videoinput')
    if (cameras.length > SINGLE_CAMERA && !isMobile) {
      setCameraSelectDisabled(false)
    }
  }

  const handleSessionId = () => {
    const params = new URLSearchParams(document.location.search)
    const id = params.get('sessionId')
    setSessionId(id)
  }

  useLayoutEffect(() => {
    ;(async () => {
      setIsLoading(true)
      await handleCameraSelectPage()
      handleSessionId()
      setIsLoading(false)
    })().catch((error) => {
      console.error(error)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <Wrapper>
        <Loader size="large" />
      </Wrapper>
    )
  }

  if (!sessionId) {
    return (
      <Wrapper>
        <Typography variant="h4">Session ID is invalid</Typography>
      </Wrapper>
    )
  }

  return (
    <FaceLivenessDetector
      disableStartScreen={cameraSelectDisabled}
      sessionId={sessionId}
      region={env.REACT_APP_REGION}
      onAnalysisComplete={handleAnalysisComplete}
      onError={(res) => {
        console.error(res)
      }}
      components={{
        ErrorView: CustomErrorView,
        PhotosensitiveWarning: () => (
          <InfoBox title="Select the camera you would like to use for Liveness Check. Please note that if you're using an external camera, it must be placed in front of your screen."></InfoBox>
        ),
      }}
    />
  )
}

export default FaceLiveness

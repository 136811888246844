import { getEnv } from '../env'

const env = getEnv()

export enum EVENTS {
  ANALYSIS_COMPLETED = 'ANALYSIS_COMPLETED',
  RETRY = 'RETRY',
}

export const postMessageToParent = (event: EVENTS) => {
  try {
    window.parent.postMessage(event, env.REACT_APP_EXTENSION_URL)
  } catch (e) {
    console.info(e)
  }
  window.parent.postMessage(event, env.REACT_APP_VAULT_URL)
}
